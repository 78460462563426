.header header {
  display: flex;
  justify-content: space-evenly;
  gap: 15px;
  background-color: #1b4e55;
  padding: 22px;
}

.header header nav {
  display: flex;
  gap: 50px;
}

.header nav > a {
  color: white;
  text-decoration-line: none;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  border: 1px solid white;
  border-radius: 3px;
  min-width: 100px;
}

.header nav > a:hover {
  transform: scale(1.1);
}

.header nav > a:active {
  transform: scale(1);
}

.linkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.active.active {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dbca2e;
  color: black;
  gap: 5px;
}

@media (width < 1300px) {
  .header header nav {
    gap: 20px;
  }
}

@media (width < 1100px) {
  .header header nav {
    gap: 10px;
  }
}

@media (width < 1000px) {
  .header header nav {
    gap: 8px;
  }

  .header nav > a {
    padding: 5px;
  }
}

@media (width < 900px) {
  .header header nav {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px;
  }

  .header nav > a {
    padding: 10px;
  }
}

@media (width < 800px) {
  .header header nav {
    gap: 30px;
  }
}

@media (width < 500px) {
  .header header {
    padding: 10px;
  }

  .header header nav {
    padding: 10px;
    gap: 10px;
  }

  .header header nav > a {
    min-width: 100px;
    text-align: center;
  }
}

@media (width < 450px) {
  .header header nav {
    padding: 10px;
    gap: 10px;
  }

  .header header nav > a {
    min-width: 50px;
    text-align: center;
    font-size: 0.7rem;
  }
}
